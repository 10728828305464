export const readMoreButton = async (err) => {
  // ** NOTE: "Read More" toggle is generated when the richtext exceeds 312 characters.

  const readMoreBtns = document.querySelectorAll('.guide-ckeditor .read-more-btn');

  readMoreBtns.forEach(btn => {
    btn.addEventListener("click", (e) => {
      const parentDiv = e.target.closest('.read-more-ckeditor'); 
      const descriptionMore = parentDiv.querySelector('.full-rich-text');
      const excerpt = parentDiv.querySelector('.read-more-excerpt'); 
      const btnText = btn.querySelector('#read-more-btn-txt');
      const icon = btn.querySelector('#read-icon');
  
      // Toggle the visibility of the full text and the excerpt
      descriptionMore.classList.toggle('read-more-hidden');
      excerpt.classList.toggle('read-more-hidden');

      // Update the button text and icon based on the current state
      if (descriptionMore.classList.contains('read-more-hidden')) {
        btnText.textContent = 'Read More';
        icon.classList.remove('read-less-icon');
        icon.classList.add('read-more-icon');
      } else {
        btnText.textContent = 'Read Less';
        icon.classList.remove('read-more-icon');
        icon.classList.add('read-less-icon');
      }
    });
  });
}