import Swiper from "swiper/bundle";

export const initSlider = async (err) => {
  if (err) {
    console.error(err);
  }

  const swipers = document.querySelectorAll(".swiper");

  function swiperNavigation(swiperInstance, prevButton, nextButton) {
    if (swiperInstance.isBeginning) {
      prevButton?.setAttribute("disabled", "disabled");
    }

    prevButton.addEventListener("click", () => swiperInstance.slidePrev());
    nextButton.addEventListener("click", () => swiperInstance.slideNext());

    swiperInstance.on("transitionEnd", disableButton);

    function disableButton() {
      if (swiperInstance.isBeginning) {
        nextButton.removeAttribute("disabled");
        prevButton.setAttribute("disabled", "disabled");
      } else if (swiperInstance.isEnd) {
        prevButton.removeAttribute("disabled");
        nextButton.setAttribute("disabled", "disabled");
      } else {
        prevButton.removeAttribute("disabled");
        nextButton.removeAttribute("disabled");
      }
    }
  }

  if (swipers.length) {
    if (document.querySelector(".bookstore-cards")) {
      const mobileNext = document.querySelector(
        ".bookstore-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".bookstore-mobile-swiper-button-prev"
      );

      var bookstoreSwiper = new Swiper(".bookstore-cards", {
        breakpoints: {
          767: {
            slidesPerView: 6.25,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".bookstore-swiper-button-next",
          prevEl: ".bookstore-swiper-button-prev",
        },
        slidesPerView: 2.75,
        spaceBetween: 16,
        pagination: {
          el: ".bookstore-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      swiperNavigation(bookstoreSwiper, mobilePrev, mobileNext);
    }

    if (document.querySelector(".authors-cards")) {
      const mobileNext = document.querySelector(
        ".authors-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".authors-mobile-swiper-button-prev"
      );

      var authorsSwiper = new Swiper(".authors-cards", {
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".authors-swiper-button-next",
          prevEl: ".authors-swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 16,
        pagination: {
          el: ".authors-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      swiperNavigation(authorsSwiper, mobilePrev, mobileNext);
    }

    if (document.querySelector(".best-of-cards")) {
      const mobileNext = document.querySelector(
        ".best-of-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".best-of-mobile-swiper-button-prev"
      );

      var bestofSwiper = new Swiper(".best-of-cards", {
        breakpoints: {
          767: {
            slidesPerView: 4.1,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".best-of-swiper-button-next",
          prevEl: ".best-of-swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 16,
        pagination: {
          el: ".best-of-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      swiperNavigation(bestofSwiper, mobilePrev, mobileNext);
    }

    if (document.querySelector(".things-to-do-cards")) {
      const mobileNext = document.querySelector(
        ".things-to-do-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".things-to-do-mobile-swiper-button-prev"
      );

      var bestofSwiper = new Swiper(".things-to-do-cards", {
        breakpoints: {
          767: {
            slidesPerView: 4.1,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".things-to-do-swiper-button-next",
          prevEl: ".things-to-do-swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 16,
        pagination: {
          el: ".things-to-do-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      swiperNavigation(bestofSwiper, mobilePrev, mobileNext);
    }

    if (document.querySelector(".popular-destinations-cards")) {
      const mobileNext = document.querySelector(
        ".popular-destinations-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".popular-destinations-mobile-swiper-button-prev"
      );

      var bestofSwiper = new Swiper(".popular-destinations-cards", {
        breakpoints: {
          767: {
            slidesPerView: 4.1,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".popular-destinations-swiper-button-next",
          prevEl: ".popular-destinations-swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 16,
        pagination: {
          el: ".popular-destinations-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      swiperNavigation(bestofSwiper, mobilePrev, mobileNext);
    }

    if (document.querySelector(".mobile-all-categories-cards")) {
      const mobileNext = document.querySelector(
        ".mobile-all-categories-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".mobile-all-categories-mobile-swiper-button-prev"
      );

      // const dynamicBullets = paginationElement.dataset.dynamicBullets === "true";

      var bestofSwiper = new Swiper(".mobile-all-categories-cards", {
        breakpoints: {
          767: {
            slidesPerView: 4.1,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".mobile-all-categories-swiper-button-next",
          prevEl: ".mobile-all-categories-swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 16,
        pagination: {
          el: ".mobile-all-categories-swiper-pagination",
          type: "bullets",
          clickable: true,
          dynamicBullets: true,
          // dynamicMainBullets: 4
        },
      });

      swiperNavigation(bestofSwiper, mobilePrev, mobileNext);
    }

    if (document.querySelector(".mobile-more-cat-articles-cards")) {
      const mobileNext = document.querySelector(
        ".mobile-more-cat-articles-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".mobile-more-cat-articles-mobile-swiper-button-prev"
      );

      var bestofSwiper = new Swiper(".mobile-more-cat-articles-cards", {
        breakpoints: {
          767: {
            slidesPerView: 4.1,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".mobile-more-cat-articles-swiper-button-next",
          prevEl: ".mobile-more-cat-articles-swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 16,
        pagination: {
          el: ".mobile-more-cat-articles-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      swiperNavigation(bestofSwiper, mobilePrev, mobileNext);
    }

    // DESTINATIONS PAGE

    if (window.location.pathname === "/destinations/") {
      swipers.forEach((currentSwiper) => {
        const swiperCardsClass = currentSwiper.classList[0];
        const dashCardsIndex = swiperCardsClass.indexOf("-cards");
        const swiperName = swiperCardsClass.slice(0, dashCardsIndex);

        const mobileNext = document.querySelector(
          `.${swiperName}-mobile-swiper-button-next`
        );
        const mobilePrev = document.querySelector(
          `.${swiperName}-mobile-swiper-button-prev`
        );
        const bullets = document.querySelector(
          `.${swiperName}-swiper-pagination`
        );

        var currentSwiperInsance = new Swiper(`.${swiperName}-cards`, {
          navigation: {
            nextEl: `.${swiperName}-swiper-button-next`,
            prevEl: `.${swiperName}-swiper-button-prev`,
          },
          slidesPerView: 1.1,
          spaceBetween: 16,
          pagination: {
            el: `.${swiperName}-swiper-pagination`,
            type: "bullets",
            clickable: true,
          },
        });

        currentSwiper.classList.add("md:hidden");
        mobileNext.classList.add("md:hidden");
        mobilePrev.classList.add("md:hidden");
        bullets.classList.add("md:hidden");

        swiperNavigation(currentSwiperInsance, mobilePrev, mobileNext);
      });
    }

    if (document.querySelector(".mobile-microsite-articles-1-cards")) {
      const mobileNext = document.querySelector(
        ".mobile-microsite-articles-1-mobile-swiper-button-next"
      );
      const mobilePrev = document.querySelector(
        ".mobile-microsite-articles-1-mobile-swiper-button-prev"
      );

      var bestofSwiper = new Swiper(".mobile-microsite-articles-1-cards", {
        breakpoints: {
          767: {
            slidesPerView: 4.1,
            spaceBetween: 24,
          },
        },
        navigation: {
          nextEl: ".mobile-microsite-articles-1-swiper-button-next",
          prevEl: ".mobile-microsite-articles-1-swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 16,
        pagination: {
          el: ".mobile-microsite-articles-1-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      swiperNavigation(bestofSwiper, mobilePrev, mobileNext);
    }
  }
};
