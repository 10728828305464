import Swiper from 'swiper/bundle';


export const initImageGalleryBlock = async (err) => {
  if (err) {
    console.error(err);
  }

  const TIMER = 3000; //milliseconds

  // new Swiper(".image_gallery_block .mySwiper", {

  new Swiper("#image-gallery", {
    spaceBetween: 0,
    speed: 400,
    autoHeight: true,
    centeredSlides: true,
    autoplay: {
      delay: TIMER,
      disableOnInteraction: false
    },
    pagination: {
      el: ".image_gallery_block .progress-nav-bar .swiper-pagination",
      clickable: true,
      type: 'custom',
      renderCustom: function ({ wrapperEl }, current, total) {

        const SLIDER_ID = wrapperEl?.id;

        let text = '';
        for (let i = 1; i <= total; i++) {
          if (SLIDER_ID && current == i) {
            /* Append the unique slider ID to progress bar */
            text += `<div class="active-bar-bg"><div class="active-bar-${SLIDER_ID}"></div></div>`;
          }

          else {
            text += `<div class="red-bar" style="--bar-default:${100 / total}%"></div>`;
          }
        }

        return text;
      },
    },
    navigation: {
      nextEl: ".image_gallery_block .swiper-button-next",
      prevEl: ".image_gallery_block .swiper-button-prev"
    },
    on: {
      autoplayTimeLeft(s, timeLeft, percentage) {
        const SLIDER_ID = s?.slidesEl?.id;

        if (SLIDER_ID) {
          const progressLineActive = document.querySelector(`.active-bar-${SLIDER_ID}`);

          progressLineActive.style.setProperty("--bar-current", 1 - percentage);
          progressLineActive.style.width = `calc(100% * var(--bar-current))`
        }

      }
    }
  });

};

