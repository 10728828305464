//import Alpine from 'alpinejs';
import { initFlowbite } from "flowbite";
import { initSlider } from "./components/slider";
import { initImageGalleryBlock } from "./components/imageGallery";
import { readMoreButton } from "./components/readMoreButton";

// import Swiper styles

// Components

// Global
//window.Alpine = Alpine;

// Inits
//Alpine.start();

document.addEventListener("DOMContentLoaded", function () {
  initSlider();
  initImageGalleryBlock();
  readMoreButton();
  initFlowbite();
});
